import HomeIcon from '@mui/icons-material/Home';
import { Box, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { login } from '../../api';
import logo from '../../assets/images/w-logo.png';
import './styles.scss';

const Signin = () => {
    const [message, setMessage] = useState()
    const cookie = new Cookies()
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: () => {
            const username = formik.values.username
            const password = formik.values.password;
            const func = login(username, password)
            if (username === "" && password === "") {
                navigate('/signin');
            } else {
                func.then(async (response) => {
                    if (response.success) {
                        cookie.set('token', response.token, { path: '/' })
                        cookie.set('firstname', response.data.firstName, { path: '/' })
                        cookie.set('lastname', response.data.lastName, { path: '/' })
                        cookie.set('username', response.data.username, { path: '/' })
                        cookie.set('id', response.data._id, { path: '/' })
                        navigate('/');
                    } else {
                        setMessage('User not found')
                    }
                })
            }

        }
    })
    return (
        <Box className='signinWrapper'>
            <form onSubmit={formik.handleSubmit}>
                <Link to='/'>
                    <Box className="signinback"><HomeIcon /></Box>
                </Link>
                <Typography variant='h3' sx={{ mt: 3, mb: 2, textAlign: "center" }}>
                    <img src={logo} width={45} height={55} alt='footer-logo' />
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "center", color: 'white' }}>
                    Enter Your Credentials to Proceed
                </Typography>
                <label htmlFor="username">Username</label>
                <input
                    type="text"
                    placeholder="Email or Phone"
                    id="username"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.username || ''}
                />
                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    placeholder="Password"
                    id="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password || ''}
                />
                <button type="submit">Log In</button>
            </form>
        </Box>
    )
}

export default Signin
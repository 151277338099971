import React from 'react';
import './styles.scss';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';

const SystemFirstComponent = () => {
    return (
        <Box className='systemFirstComponent'>
            <Card className="systemFirstComponentCard" elevation={0}>
                <CardContent>
                    <Grid container spacing={3} justifyContent="center" alignItems="stretch">
                        <Grid item xs={12} md={8} className="systemFirstComponentMainContent">
                            <Typography variant="h5" component="h2" gutterBottom className='systemFirstComponentContentTitle'>
                                Enhanced Digital Security Framework
                            </Typography>
                            <Typography variant="body1" paragraph className='systemFirstComponentContent'>
                                A unified barrier designed for online businesses, our system mitigates the hazards associated with web-based financial dealings and electronic fund transfers, facilitating uninterrupted and secure user engagements. Our system is a plug-and-play solution that promises rapid and straightforward integration.
                            </Typography>

                            <Typography variant="h6" component="h3" gutterBottom className='systemFirstComponentContentTitle'>
                                Key Attributes of Our Framework
                            </Typography>
                            <Typography variant="body1" paragraph className='systemFirstComponentContent'>
                                Our system's agility supports the bespoke activation of essential security functions to fortify the defenses of your digital ventures. It amalgamates state-of-the-art technology with user behavior analytics to deliver convenience without sacrificing safety. Deploy extensive counter-fraud mechanisms across your operation's breadth with our all-in-one dashboard that streamlines management and bolsters governance.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={4} className="systemFirstComponentSidebar">
                            <Box className='systemFirstComponentSideContent'>
                                <Typography variant="subtitle1" gutterBottom className='systemFirstComponentContentTitle'>
                                    Comprehensive Management Dashboard
                                </Typography>
                                <Typography variant="body2" paragraph className='systemFirstComponentContent'>
                                    Consolidate the capabilities of separate products into a single ecosystem, achieving excellence in operations and efficiency in tech administration.
                                </Typography>
                            </Box>
                            <Box className='systemFirstComponentSideContent'>
                                <Typography variant="subtitle1" gutterBottom className='systemFirstComponentContentTitle'>
                                    Seamless System Integration
                                </Typography>
                                <Typography variant="body2" paragraph className='systemFirstComponentContent'>
                                    Engineered to integrate effortlessly, our platform conforms to diverse customer workflows while promoting an intuitive user experience.
                                </Typography>
                            </Box>
                            <Box className='systemFirstComponentSideContent'>
                                <Typography variant="subtitle1" gutterBottom className='systemFirstComponentContentTitle'>
                                    Flexible and Expandable
                                </Typography>
                                <Typography variant="body2" paragraph className='systemFirstComponentContent'>
                                    Customize your defenses against fraudulent activity with our adaptable platform, designed to preemptively evolve in the face of new security challenges.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}

export default SystemFirstComponent;

import React, { useEffect, useState } from 'react'
import PageIntroduciton from '../../component/common/introduction'
import './styles.scss'
import { Box, Button, Container, Grid, InputLabel, OutlinedInput, Typography } from '@mui/material'
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from 'universal-cookie';
import { useFormik } from 'formik';
import { createTicket } from '../../api';

const ContactUs = () => {
    const cookie = new Cookies()
    const [randomNumber, setRandomNumber] = useState("");
    const [dateLost, setDateLost] = useState(new Date());
    const [phoneValue, setPhoneValue] = useState()
    const [message, setMessage] = useState('')
    const [errMessage, setErrMessage] = useState('')

    const generateRandomNumber = () => {
        const randomDigits = Math.floor(Math.random() * 99999) + 10000; // Generate a random 5-digit number
        return randomDigits;
    };

    useEffect(() => {
        setRandomNumber(generateRandomNumber());
    }, []);

    const formik = useFormik({
        initialValues: {
            generatedNumber: randomNumber,
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            amountLost: '',
            dateLost: dateLost,
            message: '',
        },
        onSubmit: () => {
            const generatedNumber = randomNumber;
            const firstName = formik.values.firstname;
            const lastName = formik.values.lastname;
            const email = formik.values.email;
            const phoneNumber = phoneValue;
            const amountLost = formik.values.amountLost;
            const dateLoss = moment(dateLost).format('L');
            const message = formik.values.message;

            const data = {
                generatedNumber: randomNumber,
                sender: cookie.get('id')
            }

            console.log(generatedNumber, firstName, lastName, email, phoneNumber, amountLost, dateLoss, message)
            console.log(cookie.get('id'))
            const func = createTicket(data)
            // SendContactInfo(
            //     generatedNumber,
            //     firstName,
            //     lastName,
            //     email,
            //     phoneNumber,
            //     amountLost,
            //     dateLost,
            //     message
            // )
            func.then(async (response) => {
                if (response.success) {
                    setMessage("Ticket Successfully send.")
                    window.location.reload()
                } else {
                    setErrMessage("Ticket Failure")
                }
            })
        }
    })

    return (
        <>
            {/* <PageIntroduciton title='Connect With Us' fullPage /> */}
            <Box className='contactUsWrapper'>
                <Container>
                    <Box className='contactUsContainer'>
                        <Box>
                            <Typography variant='h3' sx={{ color: 'white', fontWeight: 'bold', marginBottom: "16px" }}>
                                Send Us a Message
                            </Typography>
                        </Box>
                        <Box textAlign='center'>
                            <Typography variant='h6' className='contactUsRandomNumber'>
                                Id : #{randomNumber}
                            </Typography>
                        </Box>
                        {
                            message &&
                            <Typography style={{ color: 'green', textAlign: 'center', padding: '4px 0px' }} variant='h6'>{message}</Typography>
                        }
                        {
                            errMessage &&
                            <Typography style={{ color: 'red', textAlign: 'center', padding: '4px 0px' }} variant='h6'>{errMessage}</Typography>
                        }
                        <form style={{ marginTop: "16px" }} onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <InputLabel htmlFor="firstname" className='contactUsInputLabel'>First Name</InputLabel>
                                    <OutlinedInput
                                        className='contactUsInput'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.firstname || ''}
                                        name='firstname'
                                        required id="firstname" placeholder="First Name" size='small' fullWidth />
                                </Grid>
                                <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <InputLabel htmlFor="lastname" className='contactUsInputLabel'>Last Name</InputLabel>
                                    <OutlinedInput
                                        className='contactUsInput'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.lastname || ''}
                                        name='lastname'
                                        required id="lastname" placeholder="Last Name" size='small' fullWidth />
                                </Grid>
                                <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <InputLabel htmlFor="email" className='contactUsInputLabel'>Email</InputLabel>
                                    <OutlinedInput
                                        className='contactUsInput'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.email || ''}
                                        name='email'
                                        required id="email" placeholder="Email" size='small' fullWidth />
                                </Grid>
                                <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <InputLabel htmlFor="email" className='contactUsInputLabel'>Email</InputLabel>
                                    <PhoneInput
                                        required
                                        defaultCountry="US"
                                        className='phoneNumberInput'
                                        placeholder="Enter phone number"
                                        value={phoneValue}
                                        onChange={setPhoneValue}
                                        name="phone"
                                        id="phone"
                                    />
                                </Grid>
                                <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <InputLabel htmlFor="amountLost" className='contactUsInputLabel'>Amount Lost</InputLabel>
                                    <OutlinedInput
                                        className='contactUsInput'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.amountLost || ''}
                                        name='amountLost'
                                        required id="amountLost" placeholder="Amount Lost" size='small' fullWidth />
                                </Grid>
                                <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <InputLabel htmlFor="amount" className='contactUsInputLabel'>Date Lost</InputLabel>
                                    <DatePicker
                                        required
                                        className='dateLossInput'
                                        selected={dateLost}
                                        name='dateLost'
                                        id='dateLost'
                                        onChange={(date) => setDateLost(date)}
                                        value={dateLost} />
                                </Grid>
                                <Grid item xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <InputLabel htmlFor="amountLost" className='contactUsInputLabel'>Message</InputLabel>
                                    <OutlinedInput
                                        className='contactUsInput'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.message}
                                        name='message'
                                        required
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        fullWidth
                                        placeholder='Write a Message'
                                    />
                                </Grid>
                                <Grid item xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Button fullWidth className='submitButton' type='submit'>
                                        Contact Us
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default ContactUs
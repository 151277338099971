import React from 'react'
import { Box } from '@mui/material'
import SlideShow from '../../component/homepage/slieshow'
import HomePageFirstComponent from '../../component/homepage/homePageFirstComponent'
import HomePageSecondComponent from '../../component/homepage/homePageSecondComponent'
import HomePageThirdComponent from '../../component/homepage/homePageThirdComponent'
import HomePageFourthComponent from '../../component/homepage/homePageFourthComponent'
import './styles.scss'

const HomePage = () => {
    return (
        <>
            <SlideShow />
            <HomePageSecondComponent />
            <HomePageFourthComponent icon2 />
            <Box className='HomePageFirstThirdWrapper'>
                <Box className='HomePageFirstThirdContainer'>
                    <HomePageFirstComponent />
                    <HomePageThirdComponent />
                </Box>
            </Box>
        </>
    )
}

export default HomePage
import React, { useEffect, useRef, useState } from 'react'
import './styles.scss'
import { Box, Container, Typography, Grid } from '@mui/material'
import CountUp from 'react-countup';

const HomePageSecondComponent = () => {

    const [isVisible, setIsVisible] = useState(false);
    const countUpRef = useRef();

    const handleScroll = () => {
        const element = countUpRef.current;
        if (!isVisible && isElementInViewport(element)) {
            setIsVisible(true);
        }
    };

    const isElementInViewport = (el) => {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    useEffect(() => {

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isVisible]);
    return (
        // <Box className="HomePageSecondComponentWrapper">
        //     <Container className="HomePageSecondComponentContainer">
        //         <Box className="HomePageSecondComponentTitlesContainer">
        //             <Typography variant='h2' className='HomePageSecondComponentTitle'>
        //                 Authenticated Advantage
        //             </Typography>
        //             <Typography variant='span22' className='HomePageSecondComponentSubTitle'>
        //                 Data That Drives Our Intelligent Security Approach
        //             </Typography>
        //         </Box>
        //         <Box className="HomePageSecondComponentContentContainer">
        //             <Box className="HomePageSecondComponentBoxContainer">
        //                 <Typography variant='h2' className="HomePageSecondComponentBoxTitle">
        //                     <div ref={countUpRef}>
        //                         {isVisible && (
        //                             <>
        //                                 +<CountUp start={0} end={220} duration={1} />K
        //                             </>
        //                         )}
        //                     </div>
        //                 </Typography>
        //                 <Typography className="HomePageSecondComponentBoxDescription">
        //                     Monthly Incident Monitoring
        //                 </Typography>
        //             </Box>
        //             <Box className="divider"></Box>
        //             <Box className="HomePageSecondComponentBoxContainer">
        //                 <Typography variant='h2' className="HomePageSecondComponentBoxTitle">
        //                     <div ref={countUpRef}>
        //                         {isVisible && (
        //                             <>
        //                                 +<CountUp start={0} end={33} duration={2} />K
        //                             </>
        //                         )}
        //                     </div>
        //                 </Typography>
        //                 <Typography className="HomePageSecondComponentBoxDescription">
        //                     Monthly Incident Surveillance
        //                 </Typography>
        //             </Box>
        //             <Box className="divider"></Box>
        //             <Box className="HomePageSecondComponentBoxContainer">
        //                 <Typography variant='h2' className="HomePageSecondComponentBoxTitle">
        //                     <div ref={countUpRef}>
        //                         {isVisible && (
        //                             <>
        //                                 +<CountUp end={100} duration={1} />% Optimality
        //                             </>
        //                         )}
        //                     </div>

        //                 </Typography>
        //                 <Typography className="HomePageSecondComponentBoxDescription">
        //                     Regular Monthly Incident Log
        //                 </Typography>
        //             </Box>
        //         </Box>
        //     </Container>
        // </Box>
        <Box className="HomePageSecondComponentWrapper">
            {/* <Container className="HomePageSecondComponentContainer"> */}
            <Grid container className='HomePageSecondComponentContainer'>
                <Grid item md={5} xs={12} className="HomePageSecondComponentTitleContainer">
                    <Typography variant='h2' className='HomePageSecondComponentTitle'>
                        Secure Success
                    </Typography>
                    <Typography variant='span22' className='HomePageSecondComponentSubTitle'>
                        Insights Fueling Our Smart Security Solutions
                    </Typography>
                </Grid>
                <Grid item md={7} xs={12} className='HomePageSecondComponentContentContainer'>
                    <Box className="HomePageSecondComponentBoxContainer">
                        <Typography variant='h3' className="HomePageSecondComponentBoxDescription">
                            Monitoring
                        </Typography>
                        <Typography variant='h1' className="HomePageSecondComponentBoxTitle">
                            <div ref={countUpRef}>
                                {isVisible && (
                                    <>
                                        +<CountUp start={0} end={250} duration={1} />K
                                    </>
                                )}
                            </div>
                        </Typography>

                    </Box>
                    <Box className="HomePageSecondComponentBoxContainer">
                        <Typography variant='h3' className="HomePageSecondComponentBoxDescription">
                            Surveillance
                        </Typography>
                        <Typography variant='h1' className="HomePageSecondComponentBoxTitle">
                            <div ref={countUpRef}>
                                {isVisible && (
                                    <>
                                        +<CountUp start={0} end={25} duration={2} />K
                                    </>
                                )}
                            </div>
                        </Typography>

                    </Box>
                    <Box className="HomePageSecondComponentBoxContainer">
                        <Typography variant='h3' className="HomePageSecondComponentBoxDescription">
                            Optimality
                        </Typography>
                        <Typography variant='h1' className="HomePageSecondComponentBoxTitle">
                            <div ref={countUpRef}>
                                {isVisible && (
                                    <>
                                        +<CountUp end={92} duration={1} />%
                                    </>
                                )}
                            </div>

                        </Typography>

                    </Box>
                </Grid>
            </Grid>
            {/* </Container> */}
        </Box>
    )
}

export default HomePageSecondComponent
import React from 'react'
import './styles.scss'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Container, Grid } from '@mui/material';
import { aboutUsServices } from '../../../data';



const AboutUsThirdComponent = () => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <Container>
            <div className="tab-container">
                <div className="tabs">
                    {aboutUsServices.map((service, index) => (
                        <button
                            key={index}
                            className={`tab ${activeTab === index ? 'active' : ''}`}
                            onClick={() => setActiveTab(index)}
                        >
                            {service.title}
                        </button>
                    ))}
                </div>
                <div className="tab-content">
                    {aboutUsServices.map((service, index) => (
                        <div
                            key={index}
                            className={`tab-panel ${activeTab === index ? 'active' : 'hidden'}`}
                        >
                            <img
                                src={service.icon}
                                alt={service.title}
                                style={{
                                    width: `${service.width}px`,
                                    height: `${service.height}px`,
                                }}
                            />
                            <Typography variant='span22'>{service.desc}</Typography>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    );
}

export default AboutUsThirdComponent
import React from 'react'
import './styles.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import background from '../../../assets/images/background.png'
import background2 from '../../../assets/images/background2.jpg'
import background3 from '../../../assets/images/background3.jpg'
import { Pagination, Autoplay } from 'swiper/modules';
import { Box, Button, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';

const SlideShow = () => {

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
        },
    };

    return (
        <>
            <Box>
                <img src={background} className='slideShowBackgroundImage' />
                <Swiper
                    loop={true}
                    pagination={pagination}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    modules={[Pagination, Autoplay]}
                    className="SlideShowWrapper"
                >
                    <SwiperSlide>
                        <Box className="SlideShowItemContainer">
                            <Box className='SlideShowtemOverlay'>
                                <Typography variant='h1' className='SlideShowtemOverlayTitle'>
                                    Engage Genuine Users, Defeat Deceptive Practices!
                                </Typography>
                                <Typography variant='span22' className='SlideShowtemOverlaySubTitle'>
                                    End the threat of new account fraud effectively!
                                </Typography>
                            </Box>
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box className="SlideShowItemContainer">
                            <Box className='SlideShowtemOverlay'>
                                <Typography variant='h1' className='SlideShowtemOverlayTitle'>
                                    Stop Account Takeovers and Eliminate Promotional<br /> Abuse in Gaming.
                                </Typography>
                                <Typography variant='span22' className='SlideShowtemOverlaySubTitle'>
                                    Explore our success in blocking account takeovers and preventing promotional fraud in the gaming sector.
                                </Typography>
                            </Box>
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box className="SlideShowItemContainer">
                            <Box className='SlideShowtemOverlay'>
                                <Typography variant='h1' className='SlideShowtemOverlayTitle'>
                                    Boost Your Online Platform with Superior<br /> Security Solutions.
                                </Typography>
                                <Typography variant='span22' className='SlideShowtemOverlaySubTitle'>
                                    Dedicated to enhancing internet safety globally, we provide strong defenses against<br /> online threats and cyber vulnerabilities.
                                </Typography>
                            </Box>
                        </Box>
                    </SwiperSlide>
                </Swiper>

            </Box>
        </>
    )
}

export default SlideShow
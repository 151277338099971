import React from 'react'
import './styles.scss'
import { Box, Container, Typography } from '@mui/material'
import { aboutUs } from '../../../data'
import { arabToRoman } from 'roman-numbers'

const AboutUsSecondComponent = () => {
    return (
        <Box className="AboutUsSecondComponentWrapper">
            <Container sx={{ padding: '32px 0px', position: 'relative', zIndex: 1 }}>
                <Box className="AboutUsSecondComponentTitlesContainer">
                    <Typography variant='h2' className='AboutUsSecondComponentTitle'>
                        Reasons to Partner With Us
                    </Typography>
                    <Typography variant='span18' className='AboutUsSecondComponentSubTitle'>
                        Committed to enhancing your digital experience, we provide a secure and nurturing environment for your growth.
                        Trusting us ensures your digital realm is managed with care and professionalism.
                    </Typography>
                </Box>
                <Box className='AboutUsSecondComponentCardsContainer container'>
                    {
                        aboutUs?.map((about, index) => {
                            return (
                                <Box class="card">
                                    <Box class="box">
                                        <Box class="content">
                                            <h2>{index < 10 && '0' + "" + (index + 1)}</h2>
                                            <h3>{about?.title}</h3>
                                            <p>{about?.subTitle}</p>
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        })
                    }


                </Box>
            </Container>
        </Box>
    )
}

export default AboutUsSecondComponent
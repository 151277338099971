import icon1 from './assets/images/icons/icon1.png'
import icon11 from './assets/images/icons/icon1-1.png'
import icon2 from './assets/images/icons/icon2.png'
import icon22 from './assets/images/icons/icon2-1.png'
import icon3 from './assets/images/icons/icon3.png'
import icon33 from './assets/images/icons/icon3-1.png'
import icon4 from './assets/images/icons/icon4.png'
import icon44 from './assets/images/icons/icon4-1.png'
import icon5 from './assets/images/icons/icon5.png'
import icon55 from './assets/images/icons/icon5-1.png'
import icon6 from './assets/images/icons/icon6.png'
import icon7 from './assets/images/icons/icon7.png'
import icon8 from './assets/images/icons/icon8.png'
import icon9 from './assets/images/icons/icon9.png'
import icon10 from './assets/images/icons/icon10.png'
import internet from './assets/images/icons/internet.png'
import fintech from './assets/images/icons/fintech.png'
import car from './assets/images/icons/car.png'
import bet from './assets/images/icons/bet.png'
import system1 from './assets/images/icons/system1.png'
import system2 from './assets/images/icons/system2.png'
import system3 from './assets/images/icons/system3.png'
import system4 from './assets/images/icons/system4.png'
import system5 from './assets/images/icons/system5.png'
import system6 from './assets/images/icons/system6.png'

export const scenarios = [
    {
        name: 'Unauthorized Account Access Prevention',
        sub: 'Ensure the security of user accounts while ensuring a seamless experience.',
        icon: icon1,
        icon2: icon11,
        width: '70',
        height: '70'
    },
    {
        name: 'Anti-Registration Fraud Measures',
        sub: 'Prevent fraudulent activities during the account opening process.',
        icon: icon2,
        icon2: icon22,
        width: '70',
        height: '70'

    },
    {
        name: 'Transaction Security',
        sub: 'Effectively tackle payment fraud using a holistic, omnichannel protection strategy.',
        icon: icon3,
        icon2: icon33,
        width: '70',
        height: '60'
    },
    {
        name: 'Credit Card Fraud Prevention',
        sub: 'Equip your banks with real-time fraud protection and analyze user behavior to mitigate the risk of fraudulent transactions.',
        icon: icon4,
        icon2: icon44,
        width: '70',
        height: '60'
    },
    {
        name: 'Detection of Money Laundering Accounts',
        sub: 'Address the money mule threat promptly, reducing manual efforts for fraud analysts.',
        icon: icon5,
        icon2: icon55,
        width: '65',
        height: '60'
    },
    {
        name: 'Protection Against Push Payment Scams',
        sub: 'Counter fraud resulting from Social Engineering Scams through user behavior analysis and a comprehensive approach.',
        icon: icon6,
        width: '70',
        height: '70'
    },
    {
        name: 'Safeguarding Against Policy Fraud',
        sub: 'Enhance security by protecting against Policy Abuse using behavioral biometrics.',
        icon: icon7,
        width: '70',
        height: '70'
    },
    {
        name: 'Automated Attacks Prevention',
        sub: 'Safeguard your business against Bot Attacks using XTNs robust Behavioral Analysis technology.',
        icon: icon8,
        width: '70',
        height: '70'
    },
    {
        name: 'Malicious Software Safeguard',
        sub: 'Secure your sensitive digital services, preventing them from becoming targets of malware campaigns.',
        icon: icon9,
        width: '70',
        height: '70'
    },
    {
        name: 'Electronic Commerce Security Measures',
        sub: 'Detect fraudulent activity by monitoring your customers digital profiles.',
        icon: icon10,
        width: '70',
        height: '70'
    },
]

export const aboutUs = [
    {
        title: "Trusted Partners",
        subTitle: "Join our journey for a safer digital business environment."
    },
    {
        title: "Result-Oriented",
        subTitle: "We celebrate your successes by securing your operations."
    },
    {
        title: "Operational Efficiency",
        subTitle: "Enhance productivity with our cutting-edge solutions."
    },
    {
        title: "Brand Protection",
        subTitle: "Shielding your brand from cyber threats effectively."
    },
    {
        title: "Adaptive Security",
        subTitle: "Our platform flexibly meets all your security needs."
    },
    {
        title: "Expert Insight",
        subTitle: "Rely on our expert anti-fraud team's vast experience."
    },
    {
        title: "Innovative Security",
        subTitle: "Leading with AI-driven security from deep human insights."
    },
    {
        title: "Unified Defense",
        subTitle: "Manage fraud across all platforms with our system."
    },
];


export const aboutUsServices = [
    {
        title: 'FinTech Pioneering',
        icon: fintech,
        width: 250,
        height: 250,
        desc: 'We spearhead the financial technology revolution, offering unparalleled security solutions that empower and protect digital transactions in the burgeoning FinTech space. Our platform ensures that innovators and disruptors can focus on their core mission without compromise.'
    },
    {
        title: 'Securing Digital Finance',
        icon: internet,
        width: 270,
        height: 270,
        desc: "Our solutions are the bulwark for financial institutions navigating the digital transformation. We safeguard sensitive data and financial transactions against cyber threats, bridging the gap between advanced security measures and maintaining seamless customer service."
    },
    {
        title: 'Gaming Security Excellence',
        icon: bet,
        width: 250,
        height: 250,
        desc: 'Online gaming platforms are protected under our vigilant security umbrella, thwarting cyber fraud and safeguarding every level of gaming interaction. Our technology is designed to maintain the integrity of gaming experiences while securing user data and in-game transactions.'
    },
    {
        title: 'Automotive Cyber-Protection',
        icon: car,
        width: 250,
        height: 250,
        desc: 'In the era of connected vehicles, our cybersecurity solutions stand as a guardian for automotive technologies. We ensure that smart vehicles, along with their associated apps and services, are shielded from cyber threats, thereby maintaining consumer trust and industry credibility.'
    },
];

export const system = [
    {
        title: 'Users',
        icon: system1,
        content: 'Streamlining secure, multi-device finance services for an intuitive and safeguarded user experience.',
    },
    {
        title: 'Indicators',
        icon: system2,
        content: 'Creating comprehensive digital profiles with tech and behavior indicators for personalized security.',
    },
    {
        title: 'Tech',
        icon: system3,
        content: 'Tech indicators assess cybersecurity, ensuring devices meet strict standards against digital threats.',
    },
    {
        title: 'Behavioral Biometrics',
        icon: system4,
        content: 'Refining security through meticulous analysis of user-device interaction biometrics.',
    },
    {
        title: 'Behavioral Analytics',
        icon: system5,
        content: 'Our analytics uncover patterns and anomalies for preemptive online fraud detection.',
    },
    {
        title: 'Risk Score',
        icon: system6,
        content: 'Evaluating engagement risks dynamically to calibrate security measures appropriately.',
    },
];



export const systemAccordion = [
    {
        id: 'panel1',
        title: 'Enhance Customer Security',
        content: 'Transition from simply knowing to actively securing your customers. Utilize our behavior-based algorithms to validate identities, securing your digital platform and its users.',
    },
    {
        id: 'panel2',
        title: 'Integrated Protection Layers',
        content: 'Deploy multifaceted anti-fraud measures across different aspects of user interaction, uniting an array of security features within a singular, cohesive product. Customize according to your needs, ensuring a scalable and code-free integration.',
    },
    {
        id: 'panel3',
        title: 'Streamlined System Integration',
        content: 'Our platform seamlessly incorporates with existing third-party or bespoke solutions, complementing and enhancing the security infrastructure already in place.',
    },
    {
        id: 'panel4',
        title: 'Cross-Channel Defense',
        content: 'Our holistic approach to fraud prevention covers all banking channels, centralizing fraud management to enhance efficiency and control across the board.',
    },
    {
        id: 'panel5',
        title: 'Optimized Fraud Prevention Workflows',
        content: 'Customize your anti-fraud response with our flexible platform, designed for high-efficiency management and rapid deployment, promising a better return on investment.',
    },
    {
        id: 'panel6',
        title: 'Seamless Security Experience',
        content: 'Our biometric analysis reduces user friction and elevates security, ensuring a seamless authentication process while upholding rigorous security standards.',
    },
];


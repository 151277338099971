import React from 'react'
import PageIntroduciton from '../../component/common/introduction'
import ScenariosFirstComponent from '../../component/scenarios/ScenariosFirstComponent'
import AboutUsFourthComponent from '../../component/aboutus/aboutUsFourthComponent'

const Scenarios = () => {
    return (
        <>
            <PageIntroduciton title="CASES" description1="Scenarios are envisioned sequences of events that outline possible" description2='outcomes and help in planning or decision-making processes.' />
            <ScenariosFirstComponent />
            <AboutUsFourthComponent />
        </>
    )
}

export default Scenarios
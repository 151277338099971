import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import './styles.scss';
import systemAnimation from '../../../assets/lottie/system.json';
import Lottie from 'react-lottie';
import { system } from '../../../data';

const SystemSecondComponent = () => {

    return (
        <>
            <Box class="grid">
                {
                    system?.map((section, index) => {
                        return (
                            <Box class="card">
                                <Box class="icon">
                                    <img src={section?.icon} />
                                </Box>
                                <h4>{section?.title}</h4>
                                <Typography>
                                    {section?.content}
                                </Typography>
                                <Box class="shine"></Box>
                                <Box class="background">
                                    <Box class="tiles">
                                        <Box class="tile tile-1"></Box>
                                        <Box class="tile tile-2"></Box>
                                        <Box class="tile tile-3"></Box>
                                        <Box class="tile tile-4"></Box>

                                        <Box class="tile tile-5"></Box>
                                        <Box class="tile tile-6"></Box>
                                        <Box class="tile tile-7"></Box>
                                        <Box class="tile tile-8"></Box>

                                        <Box class="tile tile-9"></Box>
                                        <Box class="tile tile-10"></Box>
                                    </Box>

                                    <Box class="line line-1"></Box>
                                    <Box class="line line-2"></Box>
                                    <Box class="line line-3"></Box>
                                </Box>
                            </Box>
                        )
                    })
                }

            </Box>
        </>
    )
}

export default SystemSecondComponent
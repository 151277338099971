import React, { useState } from 'react';
import './styles.scss';
import { Box, Card, CardActionArea, CardContent, Typography, Paper, Container, Grid } from '@mui/material';
import { scenarios } from '../../../data';
import Typewriter from 'typewriter-effect';

function TypewriterText({ text }) {
    return (
        <Typography variant="body1" gutterBottom>
            <Typewriter
                options={{
                    strings: text,
                    autoStart: true,
                    loop: true,
                    delay: 80,
                }}
            />
        </Typography>
    );
}

const ScenariosComponentUpdated = () => {
    const [selectedScenario, setSelectedScenario] = useState(scenarios[0]);

    return (
        // <Box className='ScenariosComponentUpdated'>
        //     <Paper elevation={3} className="selected-scenario-details">
        //         <Box
        //             className="selected-scenario-image"
        //             component="img"
        //             alt={selectedScenario.name}
        //             src={selectedScenario.icon}
        //         />
        //     </Paper>
        //     <Grid container spacing={2} justifyContent="center" alignItems="stretch" sx={{ padding: "0px 32px" }}>
        //         {scenarios.map((scenario, index) => (
        //             <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
        //                 <Card
        //                     elevation={3}
        //                     className={`scenario-card ${selectedScenario.name === scenario.name ? 'active' : ''}`}
        //                     onClick={() => setSelectedScenario(scenario)}
        //                 >
        //                     <CardActionArea>
        //                         <CardContent>
        //                             <Typography gutterBottom variant="h5" component="div">
        //                                 {scenario.name}
        //                             </Typography>
        //                             <Typography variant="body2" color="text.secondary">
        //                                 {scenario.sub}
        //                             </Typography>
        //                         </CardContent>
        //                     </CardActionArea>
        //                 </Card>
        //             </Grid>
        //         ))}
        //     </Grid>

        // </Box>
        <Container maxWidth="lg" className='ScenariosComponentUpdated'>
            <Box className="selected-scenario-display" sx={{ my: 5 }}>
                <Box
                    className="selected-scenario-image"
                    component="img"
                    alt={selectedScenario.name}
                    src={selectedScenario.icon}
                    sx={{ width: '100%', maxWidth: '150px', height: 'auto', mb: 2 }}
                />
                <Typography variant="h4" component="h3" sx={{ fontWeight: 'bold' }}>
                    {selectedScenario.name}
                </Typography>
                <Typography variant="body1" sx={{ mt: 1, mb: 3 }}>
                    {selectedScenario.desc}
                </Typography>
            </Box>
            <Grid container spacing={3} justifyContent="center">
                {scenarios.map((scenario, index) => (
                    <Grid item key={scenario.name} xs={12} sm={6} md={4} lg={3}>
                        <Card
                            elevation={0}
                            className={`scenario-card ${selectedScenario.name === scenario.name ? 'active' : ''}`}
                            onClick={() => setSelectedScenario(scenario)}
                            sx={{ cursor: 'pointer', transition: '0.3s ease' }}
                        >
                            <CardActionArea sx={{ p: 3, height: "260px" }}>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="div" fontWeight='bold'>
                                        {scenario.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {scenario.sub}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default ScenariosComponentUpdated;
